import React, { useState, useEffect } from 'react'
import Logo from "../images/app_icon_tn.png"

import Home from "../images/home_icon.png"
import Holder from "../images/place_holder.jpg"
import YouTube from "../images/yt_white.png"
import Dropdown from "./Dropdown"


import { BrowserRouter as Router, Routes, Route, Link, Form } from "react-router-dom"


function Footer() {

    const [open, setOpen] = useState(false);
    return (

        <div className='App-footer'>
            <div style={{ color: 'white', fontWeight: 'bold' }}>PULAM NEWS </div>

            <div style={{ color: 'white', fontSize: '20px' }}>Address, Address, Jaffna</div>
            <div className='App-header'>


                <Link to='https://www.youtube.com/@SukinthanReport/videos' style={{ color: 'black', width: '20%' }} className='category-element'> YOUTUBE ICON </Link>


            </div>
        </div>

    );
}
export default Footer;