import React, { useState, useEffect } from 'react'
import Logo from "../images/pulam_news.jpg"
import { getDocs, collection, where, orderBy, limit, query, doc, getDoc } from "firebase/firestore";
import { db } from "../config/firebase";

import { BrowserRouter as Router, Routes, Route, Link, Form } from "react-router-dom"


function Sports() {


    const [newsLists, setNewsList] = useState([]);
    const [interNewsLists, setInterNewsList] = useState([]);
    const [videosLists, setVideosList] = useState([]);


    const newssCollectionRef = collection(db, "Sports");





    useEffect(() => {
        const getNews = async () => {
            const data = await getDocs(newssCollectionRef);
            setNewsList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getNews();
    },);



    return (

        <div className="App-news">
            {newsLists.map((news) => {
                return (
                    <div className="News-section">
                        <Link to={`/View/${news.id}`}>
                            <div> <img src={news.newsImage} style={{ height: '90px', width: '160px' }}></img> </div>
                            <div className="News-Label">{news.newsHeading}</div>
                        </Link>

                    </div>
                );
            })}

        </div>


    );
}
export default Sports;