import React, { useState, useEffect } from 'react'
import { getDocs, collection, getDoc, doc } from "firebase/firestore";
import { db } from "../config/firebase";
import { BrowserRouter as Router, useParams, Route, Link } from "react-router-dom"

function View() {

    const { id } = useParams();
    const [newsLists, setNewsList] = useState([]);
    const newssCollectionRef = doc(db, "News", id);

    useEffect(() => {
        const getNews = async () => {
            const data = await getDoc(newssCollectionRef);
            setNewsList(data.data());
        };

        getNews();
    },);

    return (

        <div className='View-news'>

            <div className='View-heading' style={{ textAlign: 'justify' }}>{newsLists.newsHeading}</div> <br></br>
            <div> <img src={newsLists.newsImage} style={{ height: 'auto', width: '60%' }}></img> </div><br></br>
            <div className="View-body" style={{ textAlign: 'start' }}>{newsLists.newsBody}</div>

        </div>
    );
}
export default View