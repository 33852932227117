import React, { useState, useEffect } from 'react'
import Logo from "../images/app_icon_tn.png"

import Home from "../images/home_icon.png"
import Holder from "../images/place_holder.jpg"
import YouTube from "../images/yt_white.png"
import Dropdown from "./Dropdown"


import { BrowserRouter as Router, Routes, Route, Link, Form } from "react-router-dom"


function Common() {

    const [open, setOpen] = useState(false);
    return (

        <div >
            <div className='App-top'>

                <Link to='/'> <img className="App-logo" src={Logo} style={{ width: '100px' }} /></Link>

                <div className='category-bar'>

                    <Link to='/' style={{ color: '#f82525', width: '20%' }} className='category-element'> முகப்பு  </Link>
                    <Link to='/' style={{ color: '#f82525', width: '30%' }} className='category-element'>  </Link>

                    <Link to='/LocalNews' style={{ color: '#f82525', width: '20%' }} className='category-element'> இலங்கை </Link>
                    <Link to='/' style={{ color: '#f82525', width: '30%' }} className='category-element'>  </Link>


                    <Link to='/International' style={{ color: '#f82525', width: '20%' }} className='category-element'> சர்வதேசம் </Link>
                    <Link to='/' style={{ color: '#f82525', width: '30%' }} className='category-element'>  </Link>

                    <Link to='/International' style={{ color: '#f82525', width: '20%' }} className='category-element'> </Link>
                    <Link to='/' style={{ color: '#f82525', width: '30%' }} className='category-element'>  </Link>

                    <Link to='/Cinema' style={{ color: '#f82525', width: '20%' }} className='category-element'> சினிமா </Link>
                    <Link to='/' style={{ color: '#FFF', width: '30%' }} className='category-element'>  </Link>

                    <Link to='/Sports' style={{ color: '#f82525', width: '20%' }} className='category-element'> விளையாட்டு </Link>
                    <Link to='/' style={{ color: '#FFF', width: '30%' }} className='category-element'>  </Link>

                    <Link to='https://www.youtube.com/@SukinthanReport/videos' style={{ color: '#f82525', width: '20%' }} className='category-element' > காணொளி
                    </Link>



                </div>



            </div>
        </div>

    );
}
export default Common;