import logo from './logo.svg';
import './App.css';
import CommonPage from "./pages/Common"

import FooterPage from "./pages/Footer"
import HomePage from "./pages/Home"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"


import LocalNews from './pages/LocalNews'
import International from './pages/International'
import Cinema from './pages/Cinema'
import Sports from './pages/Sports'
import View from './pages/View';

import ViewImportant from './pages/ViewImportant';

import ViewInternational from './pages/ViewInternational';

import ViewCinema from './pages/ViewCinema';

import ViewSports from './pages/ViewSports';

import ViewBreakings from './pages/ViewBreakings';

function App() {
  return (


    <div className="App">

      <Router>
        <div className="App-header">
          <CommonPage />

        </div>
        <Routes>

          <Route path="/View/:id" element={<View />} />

          <Route path="/ViewImportant/:id" element={<ViewImportant />} />

          <Route path="/ViewInternational/:id" element={<ViewInternational />} />

          <Route path="/ViewCinema/:id" element={<ViewCinema />} />

          <Route path="/ViewSports/:id" element={<ViewSports />} />

          <Route path="/ViewBreakings/:id" element={<ViewBreakings />} />


          <Route path="/" element={<HomePage />} />
          <Route path="/LocalNews" element={<LocalNews />} />

          <Route path="/International" element={<International />} />

          <Route path="/Cinema" element={<Cinema />} />

          <Route path="/Sports" element={<Sports />} />

        </Routes>

        <div className="App-header">
          <FooterPage />

        </div>
      </Router>
    </div>



  );
}

export default App;
