import React, { useState, useEffect } from 'react'
import Logo from "../images/pulam_news.jpg"
import { getDocs, collection, where, orderBy, limit, query, doc, getDoc } from "firebase/firestore";
import { db } from "../config/firebase";

import { BrowserRouter as Router, Routes, Route, Link, Form } from "react-router-dom"


function LocalNews() {


    const [newsLists, setNewsList] = useState([]);
    const [interNewsLists, setInterNewsList] = useState([]);
    const [videosLists, setVideosList] = useState([]);


    const newssCollectionRef = collection(db, "News");





    useEffect(() => {
        const getNews = async () => {
            const data = await getDocs(newssCollectionRef);
            setNewsList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getNews();
    },);



    return (

        <div className="App-body">

            <div className="App-news">

                <div className="News-Section">

                    {newsLists.map((news) => {

                        return (

                            <Link to={`/View/${news.id}`}>

                                <div className="Single-news">


                                    <div> <img src={news.newsImage} style={{ height: '50px', width: '90px' }}></img> </div>


                                    <div className="Single-newsDate">


                                        <div className="News-Label">{news.newsHeading}</div>
                                        <div className="date">{(news.date).toDate().toISOString().substring(0, 10)}</div>


                                    </div>
                                </div>
                            </Link>

                        );
                    })}

                </div>
            </div>
        </div>

    );
}
export default LocalNews;